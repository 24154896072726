@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');



body {
  font-family: 'Roboto', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */
.team-member-img {
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader {
    border-top-color: transparent;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  